$(function() {
  var setWrap = $("#grobal-wrapper"),
    setBase = $(".stage-base"),
    scrollSpeed = 1000,
    scrollEasing = "linear",
    slideSpeed = 500,
    slideEasing = "swing",
    downBtn = "show", // 'show' or 'hide'
    urlHash = "on", // 'on' or 'off'
    setHash = "!page";

  var setPages = setWrap.find("section");
  setWrap.find("section:first").addClass("active-page");

  var url = document.URL,
    stageSlide = $(".stage-slide");

  setWrap.append('<nav id="page-nav"><ul></ul></nav>');
  setBase.each(function(i) {
    $("#page-nav ul").append(
      '<li class="page-pn' +
      (i + 1) +
      '"><a href="javascript:void(0);"></a></li>'
    );
  });

  if (downBtn == "show") {
    setWrap
      .find("section:first")
      .append('<div id="page-down"><a href="javascript:void(0);"></a></div>');
  }

  var coreNav = $("#page-nav"),
    setNav = coreNav.find("ul"),
    navList = setNav.find("li"),
    navLength = navList.length;
  setNav.find("li:first").addClass("active-stage");
  $("body").attr("data-page", "1");

  $(window).load(function() {
    $(".animated").css("opacity", 0);
    // StageHeight
    $(window)
      .resize(function() {
        var wdHeight = $(window).height();
        setBase.css({ height: wdHeight });

        var resizeContTop = parseInt(setWrap.css("top"));

        if (resizeContTop === 0) {
          setWrap.css({ top: "0" });
        } else {
          var activeStagePos = setNav.find("li.active-stage");
          activeStagePos.each(function() {
            var posIndex = navList.index(this);
            setWrap.css({ top: -(wdHeight * posIndex) });
          });
        }

        coreNav.each(function() {
          var navHeight = $(this).height();
          $(this).css({ top: (wdHeight - navHeight) / 2 });
        });
      })
      .resize();

    // StageSlide
    stageSlide.each(function() {
      var thisSlide = $(this),
        chdPanel = thisSlide.find(".slide-panel"),
        chdPanel_movie = chdPanel.hasClass("slide-movie"),
        chdPanelLength = chdPanel.length;

      chdPanel
        .eq("0")
        .addClass("active-panel")
        .end()
        .wrapAll('<div class="slide-wrap"></div>');
      thisSlide.append(
        '<a href="javascript:void(0);" class="sd-prev"></a><a href="javascript:void(0);" class="sd-next"></a><div class="slide-nav"></div>'
      );

      var thisWrap = thisSlide.find(".slide-wrap"),
        thisPrev = thisSlide.find(".sd-prev"),
        thisNext = thisSlide.find(".sd-next"),
        thisPn = thisSlide.find(".slide-nav");

      chdPanel.each(function(i) {
        if ($(this).hasClass("slide-movie")) {
          thisPn.append(
            '<a href="javascript:void(0);" class="slide-pn' +
            (i + 1) +
            ' pn-movie"></a>'
          );
        } else {
          thisPn.append(
            '<a href="javascript:void(0);" class="slide-pn' + (i + 1) + '"></a>'
          );
        }
      });

      var pnPoint = thisPn.find("a"),
        pnFirst = thisPn.find("a:first"),
        pnLast = thisPn.find("a:last"),
        pnCount = thisPn.find("a").length;

      pnFirst.addClass("pnActive");

      pnPoint.click(function() {
        var pnNum = pnPoint.index(this),
          mvWidth = chdPanel.width(),
          wpWidth = thisWrap.width(),
          moveLeft = mvWidth * pnNum;
        thisWrap.stop().animate({ left: -moveLeft }, slideSpeed, slideEasing);
        pnPoint.removeClass("pnActive");
        $(this).addClass("pnActive");
        pnAcvCheck();
      });

      thisPrev.click(function() {
        thisWrap.not(":animated").each(function() {
          thisPn.find(".pnActive").prev().click();
          //movie制御
          videoControl("stopVideo");
          pnAcvCheck();
        });
      });

      thisNext.click(function() {
        thisWrap.not(":animated").each(function() {
          thisPn.find(".pnActive").next().click();
          //movie制御
          videoControl("stopVideo");
          pnAcvCheck();
        });
      });

      function pnAcvCheck() {
        var pnAcvNum = thisPn.find(".pnActive");
        pnAcvNum.each(function() {
          var acvIndex = pnPoint.index(this);
          let acvCount = acvIndex + 1;
          if (1 == acvCount) {
            thisPrev.css({ display: "none" });
            thisNext.css({ display: "block" });
          } else if (pnCount == acvCount) {
            thisPrev.css({ display: "block" });
            thisNext.css({ display: "none" });
          } else {
            thisPrev.css({ display: "block" });
            thisNext.css({ display: "block" });
          }
          chdPanel
            .removeClass("active-panel")
            .eq(acvIndex)
            .addClass("active-panel");
        });
      }
      pnAcvCheck();

      $(window)
        .resize(function() {
          var setWrapLeft = parseInt(thisWrap.css("left")),
            setPanelWidth = chdPanel.width(),
            setLeft = setWrapLeft / setPanelWidth;

          var sdWidth = $(window).width(),
            sdHeight = $(window).height();
          thisSlide.css({ width: sdWidth, height: sdHeight });
          thisWrap.css({ width: sdWidth * chdPanelLength, height: sdHeight });
          chdPanel.css({ width: sdWidth, height: sdHeight });

          var setWidth = chdPanel.width(),
            adjLeft = setWidth * setLeft;
          thisWrap.css({ left: adjLeft });
        })
        .resize();

      var thisInt = thisWrap.find(".slideInitial");
      thisInt.each(function() {
        var pnlInt = thisWrap.find(".slideInitial");
        pnlInt.each(function() {
          var intIndex = chdPanel.index(this);
          pnPoint.eq(intIndex).click();
        });
      });
      setTimeout(function() {
        thisSlide
          .css({ visibility: "visible", opacity: "0" })
          .animate({ opacity: "1" }, slideSpeed);
      }, slideSpeed);
    });

    // MouseWheelEvent
    var mousewheelevent =
      "onwheel" in document
        ? "wheel"
        : "onmousewheel" in document
          ? "mousewheel"
          : "DOMMouseScroll";
    $(document).on(mousewheelevent, function(e) {
      if (!setWrap.is(":animated")) {
        e.preventDefault();
        var delta = e.originalEvent.deltaY
          ? -e.originalEvent.deltaY
          : e.originalEvent.wheelDelta
            ? e.originalEvent.wheelDelta
            : -e.originalEvent.detail;
        if (delta < 0) {
          motionDown();
        } else {
          motionUp();
        }
      }
    });

    // KeyEvent
    $("html").keydown(function(e) {
      if (setWrap.is(":animated") || setWrap.find("*").is(":animated")) {
        e.preventDefault();
      } else {
        var acvStgSwP = parseInt($("body").attr("data-page"));
        switch (e.which) {
          case 33: // Key[PgUp]
            e.preventDefault();
            motionUp();
            break;

          case 34: // Key[PgDn]
            e.preventDefault();
            motionDown();
            break;

          case 38: // Key[↑]
            e.preventDefault();
            motionUp();
            break;

          case 40: // Key[↓]
            e.preventDefault();
            motionDown();
            break;

          case 37: // Key[←]
            e.preventDefault();
            var dsChkP = $("#stage" + acvStgSwP + " .sd-prev").css("display");
            if (!(dsChkP == "none")) {
              $("#stage" + acvStgSwP + " .sd-prev").click();
            }
            break;

          case 39: // Key[→]
            e.preventDefault();
            var dsChkN = $("#stage" + acvStgSwP + " .sd-next").css("display");
            if (!(dsChkN == "none")) {
              $("#stage" + acvStgSwP + " .sd-next").click();
            }
            break;
        }
      }
    });

    // FlickEvent
    var isTouch = "ontouchstart" in window;
    setWrap.on({
      touchstart: function(e) {
        if (setWrap.is(":animated")) {
          e.preventDefault();
        } else {
          this.pageY = isTouch ? event.changedTouches[0].pageY : e.pageY;
          this.topBegin = parseInt($(this).css("top"));
          this.top = parseInt($(this).css("top"));
          this.touched = true;
        }
      },
      touchmove: function(e) {
        if (!this.touched) {
          return;
        }
        e.preventDefault();
        this.top =
          this.top -
          (this.pageY - (isTouch ? event.changedTouches[0].pageY : e.pageY));
        this.pageY = isTouch ? event.changedTouches[0].pageY : e.pageY;
      },
      touchend: function(e) {
        if (!this.touched) {
          return;
        }
        this.touched = false;

        if (this.topBegin - 30 > this.top) {
          motionDown();
        } else if (this.topBegin + 30 < this.top) {
          motionUp();
        }
      },
    });

    // ScrollUpEvent
    function motionUp() {
      var stageHeightU = setBase.height(),
        contTopUp = parseInt(setWrap.css("top")),
        moveTopUp = contTopUp + stageHeightU;
      $("input,textarea").blur();
      if (!(contTopUp === 0)) {
        if (
          setWrap.find("section.active-page").prev().find(".animated").length >
          "0"
        ) {
          setWrap
            .find("section.active-page")
            .prev()
            .find(".fade1")
            .delay(1000)
            .queue(function() {
              var animClass = $(this).data("animation");
              $(this).addClass(animClass);
            });
          setWrap
            .find("section.active-page")
            .prev()
            .find(".fade2")
            .delay(2000)
            .queue(function() {
              var animClass = $(this).data("animation");
              $(this).addClass(animClass);
            });
        }
        //movie制御
        videoControl("stopVideo");

        setWrap.stop().animate({ top: moveTopUp }, scrollSpeed, scrollEasing);
        setNav
          .find("li.active-stage")
          .removeClass("active-stage")
          .prev()
          .addClass("active-stage");
        setWrap
          .find("section.active-page")
          .removeClass("active-page")
          .prev()
          .addClass("active-page");
        var acvStageP = parseInt($("body").attr("data-page")),
          setPrev = acvStageP - 1;
        $("body").attr("data-page", setPrev);
        logoReplace(setPrev);
        if (downBtn == "show") {
          pagePos();
        }
      }
      if (urlHash == "on") {
        replaceHash();
      }
    }

    // ScrollDownEvent
    function motionDown() {
      var stageHeightD = setBase.height(),
        contTopDown = parseInt(setWrap.css("top")),
        moveTopDown = contTopDown - stageHeightD;
      $("input,textarea").blur();

      var contHeight = setWrap.height(),
        maxHeightAdj = -(contHeight - stageHeightD);

      if (!(contTopDown == maxHeightAdj)) {
        //movie制御
        videoControl("stopVideo");
        setWrap.stop().animate({ top: moveTopDown }, scrollSpeed, scrollEasing);

        if (
          setWrap.find("section.active-page").next().find(".animated").length >
          "0"
        ) {
          setWrap
            .find("section.active-page")
            .next()
            .find(".fade1")
            .delay(1000)
            .queue(function() {
              var animClass = $(this).data("animation");
              $(this).addClass(animClass);
            });
          setWrap
            .find("section.active-page")
            .next()
            .find(".fade2")
            .delay(2000)
            .queue(function() {
              var animClass = $(this).data("animation");
              $(this).addClass(animClass);
            });
        }
        setNav
          .find("li.active-stage")
          .removeClass("active-stage")
          .next()
          .addClass("active-stage");
        setWrap
          .find("section.active-page")
          .removeClass("active-page")
          .next()
          .addClass("active-page");

        var acvStageN = parseInt($("body").attr("data-page")),
          setNext = acvStageN + 1;
        $("body").attr("data-page", setNext);
        logoReplace(setNext);
        if (downBtn == "show") {
          pagePos();
        }
      }
      if (urlHash == "on") {
        replaceHash();
      }
    }

    // SideNaviClick
    navList.click(function() {
      if (!setWrap.is(":animated")) {
        var crtIndex = navList.index(this),
          crtHeight = $(window).height(),
          Active = setWrap.find("section").eq(crtIndex);

        setNav.find("li.active-stage").removeClass("active-stage");
        setWrap.find("section.active-page").removeClass("active-page");
        setWrap
          .stop()
          .animate({ top: -(crtHeight * crtIndex) }, scrollSpeed, scrollEasing);
        if ($(Active).find(".animated").length > "0") {
          $(Active)
            .find(".fade1")
            .delay(1000)
            .queue(function() {
              var animClass = $(this).data("animation");
              $(this).addClass(animClass);
            });
          $(Active)
            .find(".fade2")
            .delay(2000)
            .queue(function() {
              var animClass = $(this).data("animation");
              $(this).addClass(animClass);
            });
          $(Active).find(".animated").css("opacity", "");
        }
        $(this).addClass("active-stage");
        Active.addClass("active-page");

        videoControl("stopVideo");

        $("body").attr("data-page", crtIndex + 1);
        logoReplace(crtIndex + 1);
        if (downBtn == "show") {
          pagePos();
        }
        if (urlHash == "on") {
          replaceHash();
        }
      }
    });
    $("#logo a").click(function() {
      if (!setWrap.is(":animated")) {
        navList.index(0).click();
        logoReplace(1);
        if (urlHash == "on") {
          replaceHash();
        }
      }
    });

    // Page-downBtnClick
    $("#page-down a").click(function() {
      if (!setWrap.is(":animated")) {
        var navActive = setNav.find("li.active-stage");
        navActive.each(function() {
          var navIndex = navList.index(this),
            setNav = navIndex + 1;
          if (!(setNav == navLength)) {
            $(this).next().click();
          }
          logoReplace(setNav);
        });
        if (urlHash == "on") {
          replaceHash();
        }
      }
    });
    function pagePos() {
      var pnAcv = coreNav.find("li.active-stage");
      pnAcv.each(function() {
        var pnIndexN = navList.index(this),
          pnCountN = pnIndexN + 1;
        if (pnCountN == navLength) {
          $("#page-down").css({ display: "none" });
        } else {
          $("#page-down").css({ display: "block" });
        }
      });
    }

    // HashReplace
    function replaceHash() {
      var pnAcv = coreNav.find("li.active-stage");
      pnAcv.each(function() {
        var pnIndexN = navList.index(this),
          pnCountN = pnIndexN + 1;
        location.hash = setHash + pnCountN;
      });
    }
    if (urlHash == "on") {
      replaceHash();
    }

    // OpeningFade
    $("body")
      .css({ visibility: "visible", opacity: "0" })
      .animate({ opacity: "1" }, 1000);

    // LoadPageMove
    if (url.indexOf(setHash) !== -1) {
      var numSplit = url.split(setHash)[1] - 1;
      navList.eq(numSplit).click();
    }
  });

  // HashChangeEvent
  if (urlHash == "on") {
    $(window).on("hashchange", function() {
      var stateUrl = document.URL,
        hashSplit = stateUrl.split(setHash)[1] - 1;
      navList.eq(hashSplit).click();
    });
  }

  //YoutubeControl
  function videoControl(action) {
    if ($("#youtube_movie").length > 0) {
      var $playerWindow = $("#youtube_movie")[0].contentWindow;
      $playerWindow.postMessage(
        '{"event":"command","func":"' + action + '","args":""}',
        "*"
      );
    } else {
      return false;
    }
  }
  function logoReplace(num) {
    if (num == 1) {
      $("#logo")
        .children("a")
        .children("img")
        .delay(1000)
        .queue(function() {
          $(this).attr("src", "./img/menu_logo.png").dequeue();
        });
    } else {
      $("#logo")
        .children("a")
        .children("img")
        .delay(1000)
        .queue(function() {
          $(this).attr("src", "./img/title.png").dequeue();
        });
    }
  }
});
